import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import SchemaField from "../schemaField/SchemaField";
import './SchemaTestCase.css';
import GenerateIcon from '../../icons/generate_icon.png';
import RemoveIcon from '../../icons/trash_icon.png';
import EditIcon from '../../icons/save_icon.png';
import DuplicateIcon from '../../icons/copy_icon.png';
import AddIcon from '../../icons/add_icon.png';
import gptIcon from '../../icons/gpt_icon.png';
const SchemaTestCase = ({
  testCase,
  updateTestCase,
  updateTestCaseName,
  schemaElements,
  getSchemaXSD,
  getSchemaObj,
  updateSchemaInBackend,
  onRemove,
  onDuplicate,
  doesNameExist,
  isSelectingMultiple,
  onCheckboxChange,
  isEditing,
  setEditingTestCase
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fields, setFields] = useState(testCase.fields);
  const [isAddingField, setIsAddingField] = useState(false);
  const [newFieldName, setNewFieldName] = useState('');
  const [newFieldInput, setNewFieldInput] = useState('');
  const [isEditingLocal, setIsEditingLocal] = useState(isEditing);
  const [editedTestCaseName, setEditedTestCaseName] = useState(testCase.testCaseName);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingSample, setIsGeneratingSample] = useState(false);
  const editFormRef = useRef(null);  // Reference to the edit form container
  const addFieldRef = useRef(null);  // Reference for the Add Field container
  const inputEditRef = useRef(null);

  useEffect(() => {
    setFields(testCase.fields);
  }, [testCase]);

  useEffect(() => {
    setIsEditingLocal(isEditing);
    if (isEditing) {
      inputEditRef.current?.focus();
    }
  }, [isEditing]);

  useEffect(() => {
  const handleClickOutside = (event) => {
    if (isAddingField && addFieldRef.current && !addFieldRef.current.contains(event.target)) {
      handleCancelAddField();  // Cancel add field if the click is outside the add field form
    }
    if (isEditingLocal && editFormRef.current && !editFormRef.current.contains(event.target)) {
      handleCancelClick();  // Cancel edit if the click is outside the edit form
    }
  };
  // Add when the component mounts
  document.addEventListener("mousedown", handleClickOutside);
  // Clean up when the component unmounts
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, [isAddingField, isEditingLocal, addFieldRef, editFormRef]);  // Ensure the effect runs only if isEditingLocal changes

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (e) => {
    onCheckboxChange(testCase, e.target.checked);
  };

  const toggleAddField = () => {
    setIsAddingField(!isAddingField);
    setNewFieldName('');
    setNewFieldInput('');
  };

  const handleFieldNameChange = (e) => {
    setNewFieldName(e.target.value);
  };

  const handleFieldInputChange = (e) => {
    setNewFieldInput(e.target.value);
  };

  const handleAddField = () => {
    if (!newFieldName) {
      alert("Please select an initial field.");
      return;
    }
    if (!newFieldInput) {
      alert("Please enter an initial value.");
      return;
    }
    if (newFieldName.trim() && newFieldInput.trim()) {
      const newField = {
        fieldName: newFieldName,
        inputs: [newFieldInput]
      };
      const updatedFields = [...fields, newField];
      setFields(updatedFields);
      updateTestCase(testCase.testCaseName, updatedFields);
      setIsAddingField(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && isAddingField) {
      handleAddField();
    }
  };

  const handleEditKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSaveClick();
    }
  };

  const handleCancelAddField = () => {
    setIsAddingField(false);
    setNewFieldName('');
    setNewFieldInput('');
  };

  const updateField = (fieldName, newInputs) => {
    const updatedFields = fields.map(field =>
      field.fieldName === fieldName ? { ...field, inputs: newInputs } : field
    );
    setFields(updatedFields);
    updateTestCase(testCase.testCaseName, updatedFields);
  };

  const handleRemoveField = (fieldName) => {
    if (fields.length === 1) {
      alert("Test case needs at least one field");
      return;
    }
    const updatedFields = fields.filter(field => field.fieldName !== fieldName);
    setFields(updatedFields);
    updateTestCase(testCase.testCaseName, updatedFields);
  };

  const handleDeleteClick = () => {
    if (window.confirm('Are you sure you want to delete this test case?')) {
      onRemove(testCase.testCaseName);
    }
  };

  const handleDuplicateClick = () => {
    onDuplicate(testCase);
  };

  const handleEditClick = () => {
    setIsEditingLocal(true);
    setEditingTestCase(testCase.testCaseName);
  };

  const handleSaveClick = () => {
    if (!editedTestCaseName) {
      alert("Please enter a test case name.");
      return;
    }
    if (editedTestCaseName === "default") {
      alert('Test case name cannot be "default".');
      return;
    }
    const nameExists = doesNameExist(editedTestCaseName);
    if (nameExists && testCase.testCaseName !== editedTestCaseName) {
      alert("A test case with this name already exists.");
      return;
    }
    updateTestCaseName(testCase.testCaseName, editedTestCaseName);
    setIsEditingLocal(false);
    setEditingTestCase(null);
  };

  const handleCancelClick = () => {
    setEditedTestCaseName(testCase.testCaseName);
    setIsEditingLocal(false);
    setEditingTestCase(null);
  };

  const getFields = () => {
    return fields.map((field, fieldIndex) => (
      <div key={fieldIndex}>
        <SchemaField
          field={field}
          updateField={updateField}
          removeField={handleRemoveField}
          schemaElements={schemaElements}
        />
      </div>
    ));
  };

  const generate = async () => {
      setIsGenerating(true);
      const schema = getSchemaObj();
      const data = {
          'testCase': testCase,
          ...schema,
      };
      console.log(data);

      try {
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/generateXML`, data, {
              headers: {
                  'Content-Type': 'application/json'
              }
          });

          const { xml_content, xml_name, override_xml_content, override_xml_name } = response.data.results;

          // Create and download the main XML file
          const mainBlob = new Blob([xml_content], { type: 'application/xml' });
          const mainLink = document.createElement('a');
          mainLink.download = xml_name;
          mainLink.href = URL.createObjectURL(mainBlob);
          document.body.appendChild(mainLink);
          mainLink.click();
          document.body.removeChild(mainLink);

          // If override XML content is present, create and download the override XML file
          if (override_xml_content && override_xml_name) {
              const overrideBlob = new Blob([override_xml_content], { type: 'application/xml' });
              const overrideLink = document.createElement('a');
              overrideLink.download = override_xml_name;
              overrideLink.href = URL.createObjectURL(overrideBlob);
              document.body.appendChild(overrideLink);
              overrideLink.click();
              document.body.removeChild(overrideLink);
          }

          console.log('XML files downloaded successfully');

      } catch (error) {
          console.error('There was an error generating the XML!', error);
      } finally {
        setIsGenerating(false);
      }
  };


  const availableSchemaElements = schemaElements.filter(
    element => !fields.some(field => field.fieldName === element.value)
  );

    const generateWithGPT = async () => {
        try {
            setIsGeneratingSample(true);
            const data = getSchemaObj();
            console.log(data)
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/generate_gpt`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    responseType: 'blob',
                }
            );

            // Create a Blob from the response data
            const blob = new Blob([response.data], { type: 'application/xml' });

            // Create a link element and set it up for download
            const downloadUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = `${data.fileName}_AI_Generated.xml`;

            // Programmatically click the link to trigger the download
            link.click();

            // Clean up the URL object after download
            URL.revokeObjectURL(downloadUrl);
            console.log(response)
            setIsGeneratingSample(false);
        } catch (e) {
            console.log(`error: ${e}`);
            setIsGeneratingSample(false);
        }
    };

  return (
    <div className={`test-case-wrapper ${isOpen ? 'open' : ''}`}>
      <div className="test-case-header">
        <div className="test-case-header-left">
          {testCase.testCaseName !== "default" ? (
            <p className={`dropdown-button ${isOpen ? 'open' : ''}`} onClick={toggleDropdown}>
              &gt;
            </p>
          ) : (
            <div className="dropdown-placeholder"></div>
          )}
          {isEditingLocal ? (
            <div ref={editFormRef}>
              <input
                type="text"
                ref={inputEditRef}
                value={editedTestCaseName}
                onChange={(e) => setEditedTestCaseName(e.target.value)}
                onKeyPress={handleEditKeyPress}
                autoFocus  // HTML5 autoFocus attribute
              />
              <button onClick={handleSaveClick}>Save</button>
              <button onClick={handleCancelClick}>Cancel</button>
            </div>
          ) : (
            <h3>{testCase.testCaseName}</h3>
          )}
        </div>
        <div className="test-case-header-right">
          {!isSelectingMultiple ? (
              <img onClick={generate} src={GenerateIcon} alt={"Generate"} className={`generate-button ${isGenerating ? 'generating' : ''}`} title="Generate test case"/>
          ) : (
              testCase.testCaseName !== "default" &&
              <input type="checkbox" onChange={handleCheckboxChange} title="Select test case"/>
          )}
          {testCase.testCaseName !== "default" && !isEditingLocal && (
              <>
                <img onClick={handleDuplicateClick} src={DuplicateIcon} alt="edit" className="duplicate-button" title="Duplicate test case"/>
                <img onClick={handleEditClick} src={EditIcon} alt="edit" className="edit-button" title="Edit test case name"/>
                <img onClick={handleDeleteClick} src={RemoveIcon} alt="remove" className="remove-button" title="Remove this test case"/>
              </>
          )}
            {testCase.testCaseName === "default" && !isEditingLocal && (
                <>
                    <img onClick={generateWithGPT} src={gptIcon} alt={"Generate"}
                         className={`generate-sample ${isGeneratingSample ? 'generating-sample' : ''}`} title="Generate Sample XML"/>
                </>
            )}
        </div>
      </div>
      <div className="field-wrapper">
        {isOpen && getFields()}
        {isOpen && (
            <>
              {isAddingField ? (
                <div className="add-field-container" ref={addFieldRef}>
                  <select
                    value={newFieldName}
                    onChange={handleFieldNameChange}
                    className="input-box"
                    onKeyPress={handleKeyPress}
                  >
                    <option value="">Select Field Name</option>
                    {availableSchemaElements.map((option, index) => (
                      <option key={index} value={option.value}>{option.name}</option>
                    ))}
                  </select>
                  <input
                    type="text"
                    value={newFieldInput}
                    onChange={handleFieldInputChange}
                    placeholder="Field Input"
                    className="input-box"
                    onKeyPress={handleKeyPress}
                  />
                  <button onClick={handleAddField}>Submit</button>
                  <button onClick={handleCancelAddField}>Cancel</button>
                </div>
              ) : (
                <img className="add-field-btn" onClick={toggleAddField} src={AddIcon} alt="Add field"/>
              )}
            </>
        )}
      </div>
    </div>
  );
};

export default SchemaTestCase;
